exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: \"Circular\", Arial, Helvetica, sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1080px;\n  --max-container-width: 1274px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --border-color: #DDDDDD;\n  --background-color: #ffffff;\n  --btn-color: #282828;\n  --btn-primary-bg: #0072BB;\n  --btn-primary-color: #ffffff;\n  --btn-primary-hover-bg: #2390D5;\n  --btn-primaryBorder-bg: #ffffff;\n  --btn-primaryBorder-color: #2390D5;\n  --btn-primaryBorder-hover-bg: #2390D5;\n  --btn-secondary-bg: #5065DD;\n  --btn-secondary-color: #ffffff;\n  --btn-secondary-hover-bg: #5065DD;\n  --text-color: #A02F18;\n  --common-text-color: #282828;\n  --common-text-Secondry-color: #696969;\n  --background-common-color: #F8F8F8;\n  --border-secondary-color: #dadada;\n  --background-secondary-color: #F2F8FF;\n  --menu-background-color: #f3f3f3;\n}\n.NoResults-root-2jD5u{\n    margin: 30px 20px 0;\n    text-align: center;\n}\n.NoResults-container-9RPw5{\n  margin: 64px 40px 96px;\n}\n.NoResults-noHeading-3AnJb{\n    font-size: 20px;\n    margin-top: 20px;\n}\n.NoResults-noSubHeading-1E-uA{\n    font-size: 18px;\n    margin: 10px 0 0;\n    color: #707070;\n}", ""]);

// exports
exports.locals = {
	"root": "NoResults-root-2jD5u",
	"container": "NoResults-container-9RPw5",
	"noHeading": "NoResults-noHeading-3AnJb",
	"noSubHeading": "NoResults-noSubHeading-1E-uA"
};