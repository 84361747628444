exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: \"Circular\", Arial, Helvetica, sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1080px;\n  --max-container-width: 1274px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --border-color: #DDDDDD;\n  --background-color: #ffffff;\n  --btn-color: #282828;\n  --btn-primary-bg: #0072BB;\n  --btn-primary-color: #ffffff;\n  --btn-primary-hover-bg: #2390D5;\n  --btn-primaryBorder-bg: #ffffff;\n  --btn-primaryBorder-color: #2390D5;\n  --btn-primaryBorder-hover-bg: #2390D5;\n  --btn-secondary-bg: #5065DD;\n  --btn-secondary-color: #ffffff;\n  --btn-secondary-hover-bg: #5065DD;\n  --text-color: #A02F18;\n  --common-text-color: #282828;\n  --common-text-Secondry-color: #696969;\n  --background-common-color: #F8F8F8;\n  --border-secondary-color: #dadada;\n  --background-secondary-color: #F2F8FF;\n  --menu-background-color: #f3f3f3;\n}\n .ViewMessage-root-2563g {\n\tpadding-left: 20px;\n\tpadding-right: 20px;\n}\n .ViewMessage-container-1A_HT {\n\tmargin: 0px auto;\n\tpadding-top: 30px;\n\tmax-width: 1080px;\n\tmax-width: var(--max-content-width);\n}\n a,\na:hover {\n\tcolor: #5065DD;\n\tcolor: var(--btn-secondary-bg);\n}\n .ViewMessage-bgColor-2cwg3 {\n\twidth: 100%;\n\tbackground: #F7FAFF;\n}\n @media (max-width: 1023px) and (min-width: 768px) {\n\t.ViewMessage-bgColor-2cwg3 {\n\t\tpadding: 0 10px;\n\t}\n}\n @media (max-width: 767px) {\n\t.ViewMessage-bgColor-2cwg3 {\n\t\tpadding: 0 10px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"root": "ViewMessage-root-2563g",
	"container": "ViewMessage-container-1A_HT",
	"bgColor": "ViewMessage-bgColor-2cwg3"
};