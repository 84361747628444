exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: \"Circular\", Arial, Helvetica, sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1080px;\n  --max-container-width: 1274px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --border-color: #DDDDDD;\n  --background-color: #ffffff;\n  --btn-color: #282828;\n  --btn-primary-bg: #0072BB;\n  --btn-primary-color: #ffffff;\n  --btn-primary-hover-bg: #2390D5;\n  --btn-primaryBorder-bg: #ffffff;\n  --btn-primaryBorder-color: #2390D5;\n  --btn-primaryBorder-hover-bg: #2390D5;\n  --btn-secondary-bg: #5065DD;\n  --btn-secondary-color: #ffffff;\n  --btn-secondary-hover-bg: #5065DD;\n  --text-color: #A02F18;\n  --common-text-color: #282828;\n  --common-text-Secondry-color: #696969;\n  --background-common-color: #F8F8F8;\n  --border-secondary-color: #dadada;\n  --background-secondary-color: #F2F8FF;\n  --menu-background-color: #f3f3f3;\n}\n.Message-notification-3sAhe{\n  fill: #0072BB;\n  fill: var(--btn-primary-bg);\n  height: 7px;\n  width: 7px;\n  position: absolute;\n  top: 12px;\n  -webkit-transform: translate3d(4px, -8px, 0);\n          transform: translate3d(4px, -8px, 0);\n}\n@media screen and (max-width: 1199px) {\n  .Message-notification-3sAhe {\n    top: 24px;\n    -webkit-transform: translate3d(1px, -8px, 0);\n    transform: translate3d(1px, -8px, 0);\n}\n}", ""]);

// exports
exports.locals = {
	"notification": "Message-notification-3sAhe"
};